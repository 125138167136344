<template>
  <div class="z-3 w-100 h-100" :style="slidePosition">
    <div
      class="position-absolute d-flex flex-column align-items-start justify-content-start rounded-circle circle11 z-4"
      style=" width: 50%; height: 50%; top:28.75%; left:40%; transform: translate(-50%, 0%);"
    >
      <div
        class="d-flex align-items-center justify-content-center border-radius-1r bg-warning py-2 px-3 mb-3"
        style=""
      >
        <h6
          class="font-vw-2 font-w-600 mb-0 d-block fade11"
          style="letter-spacing: 1vw;
  margin-right:-0.85vw;
  text-align:right;"
        >
          1980
        </h6>
      </div>
      <h6 class="text-warning font-w-500 font-rem-1 font-vw-2-5 fade11 mb-0">
        PARTICLE DETECTOR FIRST
      </h6>
      <p class="mt-3 text-white font-vw-1-5 text-11">
        Attending the official commissioning of the 520 MeV cyclotron, Canada’s
        Prime Minister, the Right Honourable Pierre Trudeau, famously said: “I
        don't really know what a cyclotron is, but I am certainly very happy
        Canada has one.” Indeed, TRIUMF launched a new era in Canada-wide
        physics research, and the new lab quickly became a portal for Canadian
        scientists’ participation in a wave of international collaborations.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  data() {
    return {}
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    slide11(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 6000
      })

      animation
        .add({
          targets: ".circle11",
          scale: [0, 1],
          translateY: "0%",
          duration: 100,

          delay: (el, i) => 5000 + 30 * i,
          easing: "easeOutCubic"
        })
        .add({
          targets: ".fade11",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 1000 + 30 * i
        })
        .add({
          targets: ".text-11",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    }
  },
  watch: {
    innerPosition() {
      this.slide11(this.innerPosition)
    }
  }
}
</script>

<style></style>
